import youtubePicture1 from "../../public/images/youtube_preview/youtube_picture_1.jpg";
import youtubePicture2 from "../../public/images/youtube_preview/youtube_picture_2.jpg";
import youtubePicture3 from "../../public/images/youtube_preview/youtube_picture_3.jpg";

export const VIDEOS = "VIDEOS";

export const VIDEOS_INFO = [
  {
    url: "https://youtu.be/uaB5pTFJJ6g",
    preview: youtubePicture1,
    title: "Как зарегистрироваться на сайте «Высшая школа предпринимательства»?",
  },
  {
    url: "https://youtu.be/f-V7MghMsL8",
    preview: youtubePicture2,
    title: "Как начать обучение на сайте «Высшая школа предпринимательства»?",
  },
  {
    url: "https://youtu.be/IkmZRHaxvXg",
    preview: youtubePicture3,
    title: "Как оплатить обучение на сайте «Высшая школа предпринимательства»?",
  },
];
